import { $, addAction, INIT } from '@situation/setdesign.util';

const setupToggle = ($button, $wrapper) => {
    $button.on('click', () => $wrapper.slideToggle(200));
};

const navigateOnSelection = (event) => {
    const $target = $(event.target);
    const tagSlug = $target.val();
    window.location.href = tagSlug ? `/buzz/tag/${tagSlug}/` : '/buzz/';
};

addAction(INIT, () => $('.buzz-filters').each((index, element) => {
    const $container = $(element);
    const $refineButton = $container.find('.buzz-filters__refine-button');
    const $wrapper = $container.find('.buzz-filters__wrapper');
    const $options = $container.find('.buzz-filters__input');
    setupToggle($refineButton, $wrapper);
    $options.on('change', navigateOnSelection);
}));
